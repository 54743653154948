import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type PropsWithChildren, type ReactNode } from 'react'

import { GlobalHeader } from '@/components/shared/global-header'
import { BaseLayoutBody, BaseLayoutWrapper } from '@/components/shared/layouts/styled'
import { PSABanner } from '@/components/shared/psa-banner/PSABanner'
import { PSA_BANNER_KEYS } from '@/constants/psaBanner'
import { AuthModal } from '@/features/auth/components/auth-modal/AuthModal'
import { MegaMenu } from '@/features/navigation/components/mega-menu/desktop/MegaMenu'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { RemoteParamKeys } from '@/lib/firebase/config'
import { useToggle } from '@/lib/firebase/hooks/useToggle'
import { CoreWebVitalScript } from '@/scripts/CoreWebVitalsScript'
import { SpeedCurveScripts } from '@/scripts/SpeedCurveScripts'
import { Flex } from '@/ui/atoms/flex'
import { SkipNavContent, SkipNavLink } from '@/ui/atoms/skip-nav'

const GlobalFooter = dynamic(
  () => import('@/components/shared/global-footer').then((mod) => mod.GlobalFooter),
  { ssr: false }
)

const CURRENT_PSA_CAMPAIGN = PSA_BANNER_KEYS.SITE_MAINTENANCE

export type BaseLayoutProps = PropsWithChildren<{
  /**
   * Defines if the mega menu should be rendered on the search bar
   */
  searchBarChildren?: ReactNode
  /**
   * Will show the search bar in the header. Defaults to false.
   */
  showSearchBar?: boolean
  /**
   * Render an Auth Modal on the page that can be triggered on
   */
  withAuthModal?: boolean
  /**
   *  If true, the main content will fill the full container.
   */
  isMainFullHeight?: boolean
  /**
   * Include the RUM data tracking scripts to speedcurve and corewebvitals.io
   */
  includeRUMScripts?: boolean
  /**
   * RUM data page label to be used for CoreWebVitals.io
   */
  rumPageLabel?: string
  /**
   * Page name used to identify the page (currently used for the header)
   */
  pageName?: string

  /**
   * Defines custom body color for different pages
   */
  bodyColor?: string

  /**
   * Should the app banner be hidden or not
   */
  shouldHideAppBanner?: boolean
}>

/**
 * Layout defined for general pages with header + content + footer. Must be used on all root pages.
 */

export const BaseLayout = ({
  children,
  isMainFullHeight = false,
  showSearchBar = false,
  withAuthModal = false,
  includeRUMScripts = false,
  rumPageLabel,
  bodyColor,
  shouldHideAppBanner = false,
}: BaseLayoutProps) => {
  const { t } = useTranslation('common')
  const { location } = useGetLocation()
  const router = useRouter()

  const navigationDecision = useToggle(RemoteParamKeys.NAVIGATION_SRP)
  const isNavigationSrpEnabled = navigationDecision?.enabled === true

  // Check if we're on the SRP or Homepage
  const isSRPPage = router.pathname.startsWith('/srp/')
  const isHomePage = router.pathname === '/' || router.pathname === '/home/[[...params]]'

  // Only show mega menu on homepage OR on SRP when the feature flag is enabled
  const shouldShowMegaMenu = isHomePage || (isSRPPage && isNavigationSrpEnabled)

  return (
    <>
      <PSABanner psaKey={CURRENT_PSA_CAMPAIGN} />

      <BaseLayoutWrapper bodyColor={bodyColor}>
        <SkipNavLink contentId="skip-to-main">{t('skip_nav')}</SkipNavLink>

        <BaseLayoutBody>
          <GlobalHeader
            psaCampaign={CURRENT_PSA_CAMPAIGN}
            showSearchBar={showSearchBar}
            hasNavigation={shouldShowMegaMenu}
          >
            {shouldShowMegaMenu && (
              <div data-testid="mega-menu-container">
                <MegaMenu locationId={location.id} icons={!isNavigationSrpEnabled} />
              </div>
            )}
          </GlobalHeader>

          <SkipNavContent id="skip-to-main" />

          {isMainFullHeight ? (
            <Flex
              flexGrow={1}
              flexBasis="0%"
              flexShrink={1}
              id="base-layout-main-wrapper"
              as="main"
            >
              {children}
            </Flex>
          ) : (
            <main id="base-layout-main-wrapper">{children}</main>
          )}
          {withAuthModal && <AuthModal />}
          <GlobalFooter shouldHideAppBanner={shouldHideAppBanner} />
        </BaseLayoutBody>
      </BaseLayoutWrapper>

      {/* START - Temporary speed curve integration scripts to capture RUM (Real User Monitoring data) to be used for CWV audit */}
      {includeRUMScripts && process.env.NODE_ENV === 'production' && (
        <>
          <SpeedCurveScripts />
          <CoreWebVitalScript rumPageLabel={rumPageLabel} />
        </>
      )}
      {/* END */}
    </>
  )
}
